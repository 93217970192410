@media only screen and (max-width: 767px) {
  .title {
    font-size: 1.8em;
    margin: 60px;
  }
}

@media only screen and (min-width: 768px) {
  .title {
    font-size: 1.8em;
    margin: 60px 0;
  }
}

.mainContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.boxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
  grid-gap: 4em;
  width: 80%;
}