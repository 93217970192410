// Header
$headerBG: rgba(0, 0, 0, 0.9);
$headerText:  rgb(131, 131, 131);

// Footer
$footerBG: rgba(0, 0, 0, 0.9);
$footerText: #ccc;

// Box Shadow Models
$boxShadowM1: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

// Title
$title: rgb(82, 82, 82);

// NavBar Log Out
$log-out: #fa4f4f;