@media only screen and (min-width: 601px) {
  .visibleContent {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
  }

  .container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 575px;
    height: 40%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .formAdmin {
    width: 80%;
    text-align: center;
  }

  .title {
    margin: 0px 0 40px 0;
    font-size: 30px;
    color: rgb(82, 82, 82);
    font-weight: bold;
  }

  .inputForm {
    width: 100%;
    text-align: left;
  }

  .inputSection {
    margin-bottom: 1.6rem;
  }

  .submitButton {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .visibleContent {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto", sans-serif;
  }

  .container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 575px;
    height: 40%;
    box-shadow: none;
  }

  .formAdmin {
    width: 80%;
    text-align: center;
  }

  .title {
    margin: 0px 0 40px 0;
    font-size: 30px;
    color: rgb(82, 82, 82);
    font-weight: bold;
  }

  .inputForm {
    width: 100%;
    text-align: left;
  }

  .inputSection {
    margin-bottom: 1.6rem;
  }

  .submitButton {
    width: 100%;
  }
}
