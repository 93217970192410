@use '../../scss/abstracts/variables.scss' as *;
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
 
// * {
//   padding: 0;
//   margin: 0;
//   box-sizing: border-box;
// }
 
.container {
  min-height: 100vh;
  max-width: 100vw;
  min-width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  font-family: "Roboto", sans-serif;
}
 
.header {
  max-width: 100vw;
  height: 60px;
  background-color: $headerBG;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-right: 25px;
}
 
.content {
  padding: 1em;
  max-width: 100vw;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
}
 
.footer {
  display: flex;
  width: 100%;
  height: 60px;
  background-color: $footerBG;
  align-items: center;
}
 
a {
  text-decoration: none;
}
 
/* CEll */
@media only screen and (max-width: 767px) {
  /* header */
  .headerNav {
    display: none;
  }
  .headerText {
    color: $headerText;
    border: 1px solid $headerText;
    padding: 10px 15px;
    border-radius: 50px;
    margin-right: 15px;
    font-size: 15px;
  }
 
  /* Footer */
  .footerText {
    color: #ccc;
    font-weight: bold;
    margin: 25px;
    font-size: 16px;
    border-top: 1px solid #ccc;
    padding-top: 30px;
    text-align: center;
    display: block;
  }
  .footer {
    height: 16em;
  }
  .footerList {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .footerList a li {
    font-size: 16px;
    font-weight: bold;
    margin: 12.5px;
    color: #fff;
    margin-top: 40px;
  }
  .footerList a {
    border: none;
    text-decoration: none;
  }
  .footerLinks {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
 
  /* APP and others*/
 
  .appStore,
  .playStore {
    width: 135px;
    height: 41px;
    margin: 5px;
  }
  .appStore {
    display: none;
  }
  .btnExit {
    margin-right: 15px;
  }
  .hamburger {
    display: block;
    margin-left: 15px;
  }
  .menuIcon {
    display: none;
  }
  .footerDesktopLinks {
    display: none;
  }
}
 
 
/* DESK */
@media only screen and (min-width: 768px) {
  .headerNav {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    flex-grow: 1;
  }
  .headerNav li {
    color: #fff;
    cursor: pointer;
    font-size: 20px;
  }
  .btnExit {
    margin-right: 30px;
  }
  .menuIcon {
    margin-left: 30px;
  }
  .footerDesktopLinks {
    display: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}
