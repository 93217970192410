@media only screen and (max-width: 767px) {
  .mainSection {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
  }
  .mainSection div {
    display: inherit;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 2em;
  }
  .subTitle {
    margin-top: 40px;
    font-size: 1.8em;
  }
  .text {
    margin-top: 40px;
    line-height: 28px;
  }
  .titleReserva {
    font-size: 1.8em;
    margin: 60px 0 20px 0;
  }
  .messenger,
  .instagram,
  .twitter {
    margin: 20px 0;
  }
  .whatsappBtn,
  .messenger,
  .instagram,
  .twitter {
    border: none;
    text-decoration: none;
  }
  .whatsappBtn {
    margin: 20px 0;
  }
}

@media only screen and (min-width: 768px) {
  .mainSection {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: 40px 20px;
    width: 100vw;
    height: 100%;
  }
  .title {
    font-size: 2em;
    text-align: center;
    margin-bottom: 40px;
  }
  .messenger,
  .instagram,
  .twitter {
    margin: 20px 0;
  }
  .messenger {
    margin-top: 0;
  }
  .subTitle {
    margin: 40px 0;
    font-size: 1.8em;
    text-align: center;
  }
  .text {
    line-height: 28px;
    text-align: center;
  }
  .titleReserva {
    font-size: 1.8em;
    margin: 60px 0 20px 0;
  }
  .btn {
    margin: 60px 0 0 0;
  }
  .btn a,
  .whatsappBtn {
    border: none;
    text-decoration: none;
  }
  .contentFlex {
    display: inherit;
    flex-flow: row wrap;
  }
  .firstSection {
    flex-flow: column wrap;
    display: inherit;
    margin-right: 100px;
  }
  .secondSection {
    margin-left: 100px;
  }
  .secondSection a {
    flex-flow: column wrap;
    display: inherit;
  }
  .whatsappBtn {
    margin: 40px 0;
  }
}
