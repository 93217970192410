.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 3em;
}

.image {
  width: 50%;
}
