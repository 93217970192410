@use "../../scss/abstracts/mixins.scss" as *;

.container {
  width: 90%;
}

.categories {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: inherit;
  margin: 50px 0;

  @include mobile {
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-size: 30px;
    font-weight: bold;
    margin: 10px 0;
  
    @include mobile {
      text-align: center;
    }
  }

  &__cards_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%, 15em), 1fr));
    grid-auto-rows: 10rem;
    gap: 3em;
    width: 100%;
    height: 100%;
  
    @include mobile {
      text-align: center;
    }
  }
}

