@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");


.visibleContent {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.container {
  width: 200px;
  height: 200px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
}

.searchContainer {
  width: 300px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
}

.errorBtn:hover {
  cursor: default;
}


.formAdmin {
  width: 80%;
  text-align: center;
}

.title {
  margin: 0px 0 40px 0;
  font-size: 30px;
  color: rgb(82, 82, 82);
  font-weight: bold;
}

.inputForm {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}

.inputSection {
  margin-bottom: 1.6rem;
}

.submitButton {
  width: 100%;
}

.actions {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between
}