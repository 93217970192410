.containerExample1 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
  
    display: inherit;
    height: 650px;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
  }
  