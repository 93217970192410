@media only screen and (max-width: 767px) {
  .mainSection {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
  }

  .mainSection div {
    display: inherit;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 2em;
  }

  .text {
    margin-top: 40px;
    line-height: 28px;
  }

  .btn {
    margin: 60px 0 0 0;
  }

  .btn a {
    border: none;
    text-decoration: none;
  }
}

@media only screen and (min-width: 768px) {
  .mainSection {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding: 40px 20px;
    width: 100vw;
    height: 100%;
  }

  .textContainer {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 2em;
    text-align: center;
    margin-bottom: 40px;
  }

  .messenger,
  .instagram,
  .twitter {
    margin: 20px 0;
  }

  .messenger {
    margin-top: 0;
  }

  .subTitle {
    margin: 40px 0;
    font-size: 1.8em;
    text-align: center;
  }

  .text {
    line-height: 28px;
    text-align: center;
    width: 60%;
  }

  .titleReserva {
    font-size: 1.8em;
    margin: 60px 0 20px 0;
  }

  .btn {
    margin: 60px 0 0 0;
  }

  .btn a,
  .whatsappBtn {
    border: none;
    text-decoration: none;
  }

  .contentFlex {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15em, 1fr));
    grid-gap: 2em;
    width: 80%;
    min-height: 100%;
    align-self: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  .firstSection {
    flex-flow: column wrap;
    display: inherit;
    align-items: center;
    width: 20em;
    text-align: center;
  }

  .secondSection {
    text-align: center;
    flex-flow: column wrap;
    display: inherit;
    width: 20em;
    align-items: center;
  }

  .secondSection a {
    flex-flow: column wrap;
    display: inherit;
    justify-content: center;
    align-items: center;
  }

  .whatsappBtn {
    margin: 40px 0;
  }
}

@media only screen and (max-width: 767px) {
  .title {
    font-size: 1.8em;
    margin: 60px 0 20px 0;
  }
}

@media only screen and (min-width: 768px) {
  .title {
    font-size: 1.8em;
    margin: 60px 0 20px 0;
  }
}

.cards_container {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: center;
}

.card {
  min-width: 120px;
}

.button_title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}