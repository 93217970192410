.visibleContent {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 90%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.formAdmin {
  width: 80%;
  text-align: center;
  padding-bottom: 20px;
}

.title {
  margin: 20px 0 30px 0;
  font-size: 30px;
  color: rgb(82, 82, 82);
  font-weight: bold;
}

.inputForm {
  width: 100%;
  text-align: left;
}

.inputSection {
  margin-bottom: 1.6rem;
}

.submitButton {
  width: 100%;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}