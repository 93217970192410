@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

* {
  font-family: "Roboto", sans-serif;
}

.mb_20{
  margin-bottom: 20px;
}

/* TABLE */
.fl_table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl_table td,
.fl_table th {
  text-align: center;
  padding: 8px;
}

.fl_table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}

.fl_table thead th {
  color: #ffffff;
  background: #fea200;
}

.fl_table thead th:nth-child(odd) {
  color: #ffffff;
  background: #091d24;
}

.fl_table tr:nth-child(even) {
  background: #f8f8f8;
}

@media (max-width: 767px) {
  .fl_table {
    display: block;
    width: 100%;
  }
  .table-wrapper:before {
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }
  .fl_table thead,
  .fl_table tbody,
  .fl_table thead th {
    display: block;
  }
  .fl_table thead th:last-child {
    border-bottom: none;
  }
  .fl_table thead {
    float: left;
  }
  .fl_table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }
  .fl_table td,
  .fl_table th {
    padding: 20px 0.625em 0.625em 0.625em;
    height: 60px;
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    width: 120px;
    font-size: 13px;
    text-overflow: ellipsis;
  }
  .fl_table thead th {
    text-align: left;
    border-bottom: 1px solid #f7f7f9;
  }
  .fl_table tbody tr {
    display: table-cell;
  }
  .fl_table tbody tr:nth-child(odd) {
    background: none;
  }
  .fl_table tr:nth-child(even) {
    background: transparent;
  }
  .fl_table tr td:nth-child(odd) {
    background: #f8f8f8;
    border-right: 1px solid #e6e4e4;
  }
  .fl_table tr td:nth-child(even) {
    border-right: 1px solid #e6e4e4;
  }
  .fl_table tbody td {
    display: block;
    text-align: center;
  }
}
