@use "../../scss/abstracts/mixins.scss" as *;

.visibleContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.containe{
  // width: 25rem; 
  margin-bottom: 2em;

  @include mobile {
    width: 95%;
  }

  @include tablet {
    width: 80%;
  }

}

.title {
  margin: 20px 0 30px 0;
  font-size: 30px;
  color: rgb(82, 82, 82);
  font-weight: bold;
}

.inputForm {
  width: 100%;
  text-align: left;
}

.inputSection {
  margin-bottom: 1.6rem;
}

.submitButton {
  width: 100%;
}

.display-none{
  display: none;
}