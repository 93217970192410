.footer {
    width: 100%;
    min-height: 100px;
    background-color: rgba(0, 0, 0, 0.9);
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
}

.footerList {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-self: center;
}

.footerList a li {
    font-size: 16px;
    font-weight: bold;
    margin: 12.5px;
    color: #fff;
}

.footerList a {
    border: none;
    text-decoration: none;
}

.footerLinks {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.footerText {
    color: #ccc;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}