.containerSlides {
  width: 700px;
  height: 336px;
  margin: 100px 0;
}

@media only screen and (max-width: 850px) {
  .containerSlides {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .componentContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    /* align-items: center; */
    /* justify-content: center; */
    padding: 40px 20px;
  }
  .componentContainer div {
    display: inherit;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }
  .title {
    font-size: 2em;
    text-align: center
  }
  .text {
    margin-top: 30px;
    line-height: 28px;
  }
  .img {
    margin-top: 60px;
    width: 100%;
  }
}
 
.btnSection {
  display: flex;
  justify-content: space-evenly;
  min-width: 75%;
  margin-bottom: 80px;
  gap: 30px;
}

@media only screen and (min-width: 768px) {
  .componentContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    /* justify-content: center; */
    padding: 40px 20px;
  }
  .componentContainer .text {
    display: inherit;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    width: 60%;
  }
  .title {
    font-size: 2em;
    text-align: center;
  }
  .text {
    margin-top: 40px;
    line-height: 28px;
    text-align: center;
  }
  .titleReserva {
    font-size: 1.8em;
    margin: 60px 0 20px 0;
  }
  .btn {
    margin: 60px 0 0 0;
  }
  .btn a,
  .whatsappBtn {
    border: none;
    text-decoration: none;
  }
  .img {
    margin-top: 60px;
    width: 40%;
  }
}
